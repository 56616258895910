/* Bourbon */
/*@include font-face('helvetica_ce_35_thinregular', "../../fonts/webfonts/helvetica-webfont");
@include font-face('montserratregular', "../../fonts/webfonts/montserrat-bold-webfont");
@include font-face('montserratbold', "../../fonts/webfonts/montserrat-regular-webfont");*/
/* Bourbon */
.clearfix,
.group {
  zoom: 1;
}

.clearfix:before, .clearfix:after,
.group:before,
.group:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after,
.group:after {
  clear: both;
}

.buffer-xs-0 {
  margin-top: 0;
}

.buffer-xs-10 {
  margin-top: 10px;
}

.buffer-xs-20 {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .buffer-sm-0 {
    margin-top: 0;
  }
  .buffer-sm-10 {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .buffer-md-0 {
    margin-top: 0;
  }
  .buffer-md-10 {
    margin-top: 10px;
  }
}

.icheckbox_square-blue,
.iradio_square-blue {
  background-image: url("../../img/iCheck/square/blue.png");
  /* HiDPI support */
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-blue,
  .iradio_square-blue {
    background-image: url("../../img/iCheck/square/blue@2x.png");
  }
}

.bannerMfp .mfp-iframe {
  background: none;
  box-shadow: none;
}

.mfp-bg {
  z-index: 999998;
}

.mfp-wrap {
  z-index: 999999;
}

.uno-mfp-popup {
  max-width: 600px;
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative;
}

.uno-mfp-popup.white {
  background-color: white;
}

.uno-mfp-popup.white .mfp-close {
  color: black;
  font-size: 22px;
}

.uno-mfp-popup.white > .box {
  border-radius: 0;
}

.uno-mfp-popup > .popup-container {
  padding: 20px;
}

.uno-mfp-popup > .popup-container > h4 {
  margin: 0;
}

.mfp-iframe-holder .mfp-content {
  max-width: 1200px;
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.mfp-close-btn-in .mfp-close {
  color: #FFF;
}

.select2-dropdown, .select2-container--bootstrap .select2-search--dropdown .select2-search__field, .select2-container--bootstrap .select2-selection {
  border-radius: 0;
}

.slick-loading .slick-list {
  background-image: url("../../img/slick/ajax-loader.gif");
}

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick/slick.eot");
  src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg");
}

.slick-arrow:before {
  transition: opacity 0.5s;
}

/* TinyMCE Fullscreen Z-Index Override */
div.mce-fullscreen {
  z-index: 9999;
}

label.mce-label {
  max-width: none;
}

/* TINYMCE CUSTOMISATION */
.mce-window {
  max-width: 90% !important;
}

.mce-panel {
  max-width: 100% !important;
}

.mce-tabs {
  max-width: 100% !important;
}

.mce-container-body {
  max-width: 100% !important;
}

.mce-container {
  max-width: 100% !important;
}

.mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.svg");
}

.no-svg .mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.png");
}

.mejs-overlay-loading {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-overlay-loading span {
  background-image: url("../../assets/mediaelement/loading.gif");
}

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector {
  background-image: url("../../assets/mediaelement/background.png");
}

.mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.svg");
}

.no-svg .mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.png");
}

.mejs-controls .mejs-button.mejs-skip-back-button {
  background-image: url("../../assets/mediaelement/skipback.png");
}

.jstree-default .jstree-icon,
.jstree-default .jstree-file,
.jstree-default .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("../../img/jstree/throbber.gif");
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-small .jstree-icon,
.jstree-default-small .jstree-file,
.jstree-default-small .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

.jstree-default-large .jstree-icon,
.jstree-default-large .jstree-file,
.jstree-default-large .jstree-folder {
  background-image: url("../../img/jstree/32px.png");
}

#jstree-dnd.jstree-dnd-responsive > .jstree-ok,
#jstree-dnd.jstree-dnd-responsive > .jstree-er {
  background-image: url("../../img/jstree/40px.png");
}

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("../../img/jstree/40px.png");
}

/* No bold */
.no-bold {
  font-weight: normal;
}

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic;
}

/* Easy background image */
.bg-img {
  background: no-repeat center;
}

.bg-img.bg-cover {
  background-size: cover;
}

.bg-img.bg-contain {
  background-size: contain;
}

/* Button Block Text Overflow */
.btn-block {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Hide CSRF Token */
#_token {
  display: none;
}

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

/* Remove Transition */
.no-transition {
  transition: none !important;
}

a.block {
  display: block;
}

.btn:focus {
  outline: none;
}

@font-face {
  font-family: "Gotham-Book";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Gotham-Book.woff") format("woff"), url("../../fonts/webfonts/Gotham-Book.ttf") format("truetype"), url("../../fonts/webfonts/Gotham-Book.svg#Gotham-Book") format("svg");
}

@font-face {
  font-family: "Gotham-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/Gotham-Bold.woff") format("woff"), url("../../fonts/webfonts/Gotham-Bold.ttf") format("truetype"), url("../../fonts/webfonts/Gotham-Bold.svg#Gotham-Bold") format("svg");
}

#menu nav ul li a, #menu nav ul li ul li a, .searchBar input {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
}

#home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item > div a .home-cms-item-text, #banner .descs .desc .title {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
}

#banner .descs .desc p {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 30px;
  font-size: 3rem;
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-link, #home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-link a, #home-cms-4 .home-cms-content .home-cms-title .home-cms-link, #home-cms-4 .home-cms-content .home-cms-title .home-cms-link a, #home-news .home-news-content .home-news-title .home-news-link a, #home-cms-5 .home-cms-content .home-cms-link a, #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .text, #home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-description .home-cms-item .description, .btn, .btn-primary, #banner .descs .desc .link .link-text {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}

#home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div .home-cms-content-number, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content > div .home-cms-content-number, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div .home-cms-content-content > div .home-cms-content-percentage, #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-numbering, #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div .home-cms-content-content > div .home-cms-content-percentage {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 80px;
  font-size: 8rem;
  font-weight: bold;
}

#home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div .home-cms-content-text, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content > div .home-cms-content-text, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle, #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle, #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-item-description, #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 17px;
  font-size: 1.7rem;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-title, #home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-title a, #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-title .home-cms-title-wrapper .home-cms-title-text, #home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-title-wrapper .home-cms-title-text {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-link, #home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-link a, #home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-description, .inner-cms-content .inner-cms-description, .inner-cms-content .inner-cms-content-left > div .inner-cms-content-description {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a .home-cms-item-text, .inner-cms-content .inner-cms-listing ul .inner-cms-item > div .inner-cms-item-text {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

.news-item a .news-contents > div .news-title, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-title, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-title, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div .home-cms-content-title, #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-title, #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div .home-cms-content-title {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 17px;
  font-size: 1.7rem;
  font-weight: bold;
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-title, #home-cms-7 .home-cms-title, #inner-banner-wrapper .inner-banner-title .title {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
}

.news-item a .news-contents > div .news-description, .news-item a .news-contents > div .news-description .news-date {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text > div {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-person > div .person-details > div .person-name {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-person > div .person-details > div .person-company {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-title, #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-listing .home-cms-content-listing-item .text, .back-to-top > div {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}

footer #footer-content .footer-bottom-content .footer-bottom-left .text, #breadcrumb .text, #breadcrumb .crumb {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}

.inner-title, .inner-title-2, .inner-cms-content .inner-cms-title {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 40px;
  font-size: 4rem;
}

.inner-cms-content .inner-cms-content-left > div .inner-cms-content-title, .inner-cms-content .inner-cms-listing .inner-cms-item > div .inner-cms-item-subtitle {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 25px;
  font-size: 2.5rem;
}

#home-cms-2 .home-cms-content .home-cms-title, #home-cms-4 .home-cms-content .home-cms-title .home-cms-title-text, #home-news .home-news-content .home-news-title .home-news-title-text, #home-cms-5 .home-cms-content .home-cms-title .home-cms-title-text, .inner-cms-content .inner-cms-description .bold, .inner-cms-content .inner-cms-content-left > div .inner-cms-content-description .bold, .inner-cms-content .inner-cms-listing .inner-cms-item > div .inner-cms-item-title {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 25px;
  font-size: 2.5rem;
  font-weight: bold;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-item-description .bolder {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .title, #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-newsletter .text, #home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-description .home-cms-item .title {
  font-family: 'Gotham-Bold', sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Gotham-Book', sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
}

.slick-slide, .slick-slide *, *:focus {
  outline: none !important;
}

header {
  position: fixed;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

header.scrolled {
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 0;
}

header #header {
  background: linear-gradient(90deg, #FFFFFF 50%, #DC2826 50%);
}

header #header .header-content {
  display: flex;
  align-items: stretch;
}

header #header .header-content .header-left {
  flex: 1.5;
}

@media (max-width: 991px) {
  header #header .header-content .header-left {
    flex: 2;
  }
}

header #header .header-content .header-right {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #DC2826;
}

@media (max-width: 991px) {
  header #header .header-content .header-right {
    flex: 3;
  }
}

@media (max-width: 480px) {
  header #header .header-content .header-right {
    flex: 1;
  }
}

header #header .header-content .header-right .header-search-icon {
  cursor: pointer;
  padding: 1rem 1.5rem;
}

header #header .header-content .header-right .header-search-icon svg {
  font-size: 14px;
  font-size: 1.4rem;
  color: #FFFFFF;
  transition: all 0.3s linear;
}

header #header .header-content .header-right .header-search-icon svg:hover {
  color: #000000;
}

header #header .header-content .header-ib {
  flex: 0.5;
  -ms-grid-row-align: center;
  align-self: center;
}

#menu {
  position: relative;
}

#menu nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

#menu nav ul li {
  margin-right: 1rem;
  position: relative;
  text-align: center;
}

#menu nav ul li.active, #menu nav ul li:hover {
  color: #FFFFFF;
}

#menu nav ul li.active > a, #menu nav ul li:hover > a {
  color: #FFFFFF;
}

#menu nav ul li.active > a:after, #menu nav ul li:hover > a:after {
  color: #FFFFFF !important;
}

#menu nav ul li:hover > ul {
  display: block;
  opacity: 1;
  visibility: visible;
}

#menu nav ul li a {
  color: #FFFFFF;
  font-size: 14px;
  font-size: 1.4rem;
  cursor: pointer;
}

@media (max-width: 1024px) {
  #menu nav ul li a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

#menu nav ul li ul {
  display: none;
  position: absolute;
  padding-top: 2.6rem;
  top: 100%;
  left: -2rem;
  width: 200px;
  transition: all 0.3s linear;
}

@media (max-width: 1024px) {
  #menu nav ul li ul {
    padding-top: 2rem;
  }
}

#menu nav ul li ul li {
  position: relative;
  background: #EAF1F9;
  margin: 0;
  padding: 0.5rem 2rem;
  text-transform: none;
  text-align: left;
  color: #2B3139;
}

#menu nav ul li ul li:first-child {
  padding-top: 1rem;
}

#menu nav ul li ul li:last-child {
  padding-bottom: 1rem;
}

#menu nav ul li ul li:hover {
  background-color: #DC2826;
}

#menu nav ul li ul li.hasChild > a:after {
  content: '\f0da';
  font-family: 'fontAwesome';
  color: transparent;
  position: absolute;
  right: 1rem;
}

#menu nav ul li ul li a {
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: initial;
  color: #2B3139;
}

@media (max-width: 1024px) {
  #menu nav ul li ul li a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

#menu nav ul li ul li ul {
  left: 100%;
  margin: 0;
  padding-top: 0;
  top: 0;
  border: none;
}

#menu nav > ul > li > a.custom {
  color: #00B8F4;
}

.searchBar {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  text-align: center;
  padding-top: 0;
  display: none;
}

.searchBar form {
  margin: 0;
}

.searchBar input {
  width: 100% !important;
  font-size: 1.6rem;
  text-align: left;
  font-size: 14px;
  font-size: 1.4rem;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding: 1rem 1.3rem !important;
  height: auto !important;
}

#mobile-menu .searchBar {
  display: block;
  position: relative;
  padding: 0;
  margin: 1.5rem;
}

#mobile-menu .searchBar form {
  margin: 0 10px;
}

#mobile-menu .searchBar form input {
  height: 5rem !important;
  padding: 0 1rem !important;
  background: transparent !important;
  text-align: center !important;
}

#home-cms-1 .home-cms-content {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  #home-cms-1 .home-cms-content {
    display: block;
  }
}

#home-cms-1 .home-cms-content .home-cms-content-left {
  flex: 1;
}

#home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside {
  display: flex;
  align-items: center;
}

#home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content {
  flex: 1;
}

#home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  #home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  #home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div {
    display: block;
    text-align: center;
  }
}

#home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div .home-cms-content-number {
  opacity: 0;
  color: #DC2826;
}

@media (max-width: 425px) {
  #home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div .home-cms-content-number {
    font-size: 50px;
  }
}

#home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div .home-cms-content-text {
  color: #686C72;
  padding-top: 3rem;
  padding-left: 1.5rem;
  line-height: 1.5rem;
}

@media (max-width: 480px) {
  #home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div .home-cms-content-text {
    padding-top: 0;
  }
}

#home-cms-1 .home-cms-content .home-cms-content-right {
  flex: 1;
}

#home-cms-1 .home-cms-content .home-cms-content-right > div {
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  #home-cms-1 .home-cms-content .home-cms-content-right > div {
    display: block;
    margin-top: 2rem;
  }
}

#home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item {
  flex: 1;
}

#home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item > div {
  margin: 1rem 0 1rem 1rem;
}

@media (max-width: 480px) {
  #home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item > div {
    margin: 1rem;
  }
}

#home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item > div a {
  display: block;
  background: #DC2826;
  background-size: 200%;
  text-align: center;
  padding: 1rem;
  transition: background-position 0.3s ease-in;
  background-position: 0 50%;
}

#home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item > div a:hover {
  background-position: 100%;
  transition: background-position 0.3s ease-in;
}

#home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item > div a .home-cms-item-image {
  margin-bottom: 1rem;
}

#home-cms-1 .home-cms-content .home-cms-content-right > div .home-cms-item > div a .home-cms-item-text {
  color: #FFFFFF;
}

#home-cms-2 {
  padding: 3rem 0;
}

@media (max-width: 480px) {
  #home-cms-2 {
    margin: 0 1rem;
  }
}

#home-cms-2 .home-cms-content .home-cms-title {
  color: #2B3139;
  padding: 2rem 1.5rem;
  border-left: 0.4rem solid #DC2826;
  margin-bottom: 2rem;
}

#home-cms-2 .home-cms-content .home-cms-listing {
  opacity: 0;
  transition: all 0.3s ease-in;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div {
  max-width: 45rem;
  margin: 0 auto;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-image a {
  display: block;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text {
  background-color: #DC2826;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left {
  align-self: flex-end;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div {
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-title {
  color: #FFFFFF;
  text-decoration: none;
  margin-bottom: 1rem;
  line-height: 1.2;
  transition: all 0.3s linear;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-title a {
  color: #FFFFFF;
  text-decoration: none;
  transition: all 0.3s linear;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-title:hover {
  color: #2B3139;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-title:hover a {
  color: #2B3139;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-link {
  color: #FFFFFF;
  text-decoration: none;
  transition: all 0.3s linear;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-link a {
  color: #FFFFFF;
  text-decoration: none;
  transition: all 0.3s linear;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-link:hover {
  color: #2B3139;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-left > div .item-link:hover a {
  color: #2B3139;
}

#home-cms-2 .home-cms-content .home-cms-listing .home-cms-item > div .home-cms-item-text > div .home-cms-item-text-right {
  background: #DC2826;
  padding: 2rem;
  display: none;
  align-items: center;
}

#home-cms-2 .slick-prev {
  width: 4rem;
  height: 4rem;
  line-height: 4.8rem;
  font-style: normal;
  background: #DC2826;
  border-radius: 10rem;
  border: 0.1rem solid #DC2826;
  text-align: center;
  z-index: 1;
  left: -1rem;
  transition: all linear 0.3s;
}

@media (max-width: 480px) {
  #home-cms-2 .slick-prev {
    left: -2rem;
  }
}

#home-cms-2 .slick-prev:before {
  font-family: 'fontAwesome';
  content: '\f104';
  color: #FFFFFF;
  font-size: 1.7rem;
  line-height: 0;
  opacity: 1;
  transition: all linear 0.3s;
}

#home-cms-2 .slick-prev:hover {
  border-color: #DC2826;
  background: #DC2826;
}

#home-cms-2 .slick-prev:hover:before {
  color: #FFFFFF;
}

#home-cms-2 .slick-next {
  width: 4rem;
  height: 4rem;
  line-height: 4.8rem;
  font-style: normal;
  background: #DC2826;
  border-radius: 10rem;
  border: 0.1rem solid #DC2826;
  text-align: center;
  z-index: 1;
  right: -1rem;
  transition: all linear 0.3s;
}

@media (max-width: 480px) {
  #home-cms-2 .slick-next {
    right: -2rem;
  }
}

#home-cms-2 .slick-next:before {
  font-family: 'fontAwesome';
  content: '\f105';
  color: #FFFFFF;
  font-size: 1.7rem;
  line-height: 0;
  opacity: 1;
  transition: all linear 0.3s;
}

#home-cms-2 .slick-next:hover {
  border-color: #DC2826;
  background: #DC2826;
}

#home-cms-2 .slick-next:hover:before {
  color: #FFFFFF;
}

#home-cms-3 {
  background: #696969;
}

#home-cms-3 .home-cms-content {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  #home-cms-3 .home-cms-content {
    flex-wrap: wrap;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-left {
  flex: 1;
}

@media (max-width: 600px) {
  #home-cms-3 .home-cms-content .home-cms-content-left {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-left > div {
  padding-right: 5.5rem;
  padding-left: 8rem;
  position: relative;
}

@media (max-width: 480px) {
  #home-cms-3 .home-cms-content .home-cms-content-left > div {
    padding: 1rem;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-left > div:before {
  content: '';
  position: absolute;
  top: 5rem;
  left: 0;
  width: 5rem;
  height: 0.4rem;
  background: #DC2826;
}

@media (max-width: 480px) {
  #home-cms-3 .home-cms-content .home-cms-content-left > div:before {
    display: none;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-title {
  color: #FFFFFF;
  line-height: 1.2;
  margin-bottom: 2.5rem;
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-description {
  color: #FFFFFF;
  margin-bottom: 5rem;
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-link {
  color: #FFFFFF;
  text-decoration: none;
  transition: all 0.3s linear;
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-link a {
  color: #FFFFFF;
  text-decoration: none;
  transition: all 0.3s linear;
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-link:hover {
  color: #FFFFFF;
}

#home-cms-3 .home-cms-content .home-cms-content-left > div .home-cms-link:hover a {
  color: #FFFFFF;
}

#home-cms-3 .home-cms-content .home-cms-content-right {
  flex: 1;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing {
  border-left: 0.1rem solid #D7DADD;
  border-right: 0.1rem solid #D7DADD;
  margin-bottom: 0;
  opacity: 0;
}

@media (max-width: 600px) {
  #home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing {
    border-top: 0.1rem solid #D7DADD;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items {
  border-bottom: 0.1rem solid #D7DADD;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div {
  display: flex;
  align-items: center;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item {
  flex: 1;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a {
  background: transparent;
  display: block;
  text-align: center;
  padding: 4rem 0;
  transition: all 0.3s linear;
}

@media (max-width: 480px) {
  #home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a {
    padding: 2rem 0;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a:hover {
  background: #DC2826;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a:hover .home-cms-item-text {
  color: #FFFFFF;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a .home-cms-item-image {
  margin-bottom: 2rem;
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a .home-cms-item-image img {
  margin: 0 auto;
}

@media (max-width: 325px) {
  #home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a .home-cms-item-image img {
    max-width: 5rem;
    height: auto;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a .home-cms-item-text {
  color: #FFFFFF;
  transition: all 0.3s linear;
}

@media (max-width: 325px) {
  #home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item > div a .home-cms-item-text {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item:nth-child(2) > div {
  border-left: 0.1rem solid #D7DADD;
}

#home-cms-3 .slick-prev {
  width: 4rem;
  height: 4rem;
  line-height: 4.8rem;
  font-style: normal;
  background: #FFFFFF;
  border-radius: 10rem;
  border: 0.1rem solid #D7DADD;
  text-align: center;
  z-index: 1;
  left: 50%;
  top: -2rem;
  transform: translateX(-50%);
  transition: all linear 0.3s;
}

#home-cms-3 .slick-prev:before {
  font-family: 'fontAwesome';
  content: '\f106';
  color: #2B3139;
  font-size: 1.6rem;
  line-height: 0;
  opacity: 1;
  transition: all linear 0.3s;
}

#home-cms-3 .slick-prev:hover {
  border-color: #DC2826;
  background: #DC2826;
}

#home-cms-3 .slick-prev:hover:before {
  color: #FFFFFF;
}

#home-cms-3 .slick-next {
  width: 4rem;
  height: 4rem;
  line-height: 4.8rem;
  font-style: normal;
  background: #FFFFFF;
  border-radius: 10rem;
  border: 0.1rem solid #D7DADD;
  text-align: center;
  z-index: 1;
  left: 50%;
  bottom: -2rem;
  top: auto;
  transform: translateX(-50%);
  transition: all linear 0.3s;
}

#home-cms-3 .slick-next:before {
  font-family: 'fontAwesome';
  content: '\f107';
  color: #2B3139;
  font-size: 1.6rem;
  line-height: 0;
  opacity: 1;
  transition: all linear 0.3s;
}

#home-cms-3 .slick-next:hover {
  border-color: #DC2826;
  background: #DC2826;
}

#home-cms-3 .slick-next:hover:before {
  color: #FFFFFF;
}

#home-cms-4 {
  margin-bottom: 8rem;
}

@media (max-width: 480px) {
  #home-cms-4 {
    margin-bottom: 4rem;
  }
}

#home-cms-4 .home-cms-content .home-cms-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 0.4rem solid #DC2826;
  margin-bottom: 2rem;
}

#home-cms-4 .home-cms-content .home-cms-title .home-cms-title-text {
  color: #2B3139;
  padding-left: 1.5rem;
}

#home-cms-4 .home-cms-content .home-cms-title .home-cms-link {
  color: #DC2826;
  text-decoration: none;
  transition: all 0.3s linear;
}

#home-cms-4 .home-cms-content .home-cms-title .home-cms-link a {
  color: #DC2826;
  text-decoration: none;
  transition: all 0.3s linear;
}

#home-cms-4 .home-cms-content .home-cms-title .home-cms-link:hover {
  color: #9B1122;
}

#home-cms-4 .home-cms-content .home-cms-title .home-cms-link:hover a {
  color: #9B1122;
}

#home-cms-4 .home-cms-content .home-cms-listing > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 991px) {
  #home-cms-4 .home-cms-content .home-cms-listing > div {
    flex-wrap: wrap;
  }
}

#home-cms-4 .home-cms-content .home-cms-listing > div .home-cms-item {
  width: calc(100% / 6);
}

@media (max-width: 991px) {
  #home-cms-4 .home-cms-content .home-cms-listing > div .home-cms-item {
    width: calc(100% / 3);
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  #home-cms-4 .home-cms-content .home-cms-listing > div .home-cms-item {
    width: calc(100% / 2);
  }
}

#home-cms-4 .home-cms-content .home-cms-listing > div .home-cms-item img {
  display: block;
  margin: 0 auto;
}

#home-news .home-news-content {
  padding-bottom: 4rem;
  border-bottom: 1px solid #D7DADD;
}

#home-news .home-news-content .home-news-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-left: 0.4rem solid #DC2826;
  margin-bottom: 3rem;
}

@media (max-width: 375px) {
  #home-news .home-news-content .home-news-title {
    display: block;
  }
}

#home-news .home-news-content .home-news-title .home-news-title-text {
  color: #2B3139;
  padding-left: 1.5rem;
  text-transform: uppercase;
}

@media (max-width: 375px) {
  #home-news .home-news-content .home-news-title .home-news-link {
    margin-top: 1.5rem;
  }
}

#home-news .home-news-content .home-news-title .home-news-link a {
  display: inline-block;
  color: #DC2826;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  border: 1px solid #DC2826;
  background: transparent;
  border-radius: 25px;
  transition: all 0.3s linear;
}

#home-news .home-news-content .home-news-title .home-news-link a:hover {
  background: #DC2826;
  color: #FFFFFF;
}

#home-news .home-news-content .home-news-title .home-news-link a svg {
  margin-left: 0.5rem;
}

#home-news .home-news-content .home-news-listing {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

@media (max-width: 480px) {
  #home-news .home-news-content .home-news-listing {
    display: block;
  }
}

.news-item {
  flex: 1;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

@media (max-width: 991px) {
  .news-item {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (max-width: 480px) {
  .news-item {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 3rem;
  }
}

.news-item a {
  display: block;
  padding: 0 1.5rem;
}

.news-item a .news-image {
  height: 200px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.news-item a .news-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.news-item a:hover .news-description {
  color: #2B3139 !important;
}

.news-item a:hover .news-date {
  color: #2B3139 !important;
}

.news-item a .news-contents > div .news-title {
  margin-bottom: 2rem;
  color: #2B3139;
  transition: all 0.3s linear;
}

.news-item a .news-contents > div .news-title:hover {
  color: #DC2826;
}

.news-item a .news-contents > div .news-description {
  color: #A2A2A2;
  transition: all 0.3s linear;
}

.news-item a .news-contents > div .news-description .news-date {
  color: #A2A2A2;
  transition: all 0.3s linear;
}

#news-listing {
  display: flex;
  margin: 0 -1.5rem;
  margin-top: 2rem;
  flex-wrap: wrap;
}

#news-listing .news-item {
  width: calc(100% / 3);
  flex: unset;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  #news-listing .news-item {
    width: calc(100% / 2);
  }
}

@media (max-width: 480px) {
  #news-listing .news-item {
    width: 100%;
  }
}

#home-cms-5 {
  padding: 5rem 0;
  background-color: #FFFFFF;
}

#home-cms-5 .home-cms-content .home-cms-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 0.4rem solid #DC2826;
  margin-bottom: 3rem;
}

#home-cms-5 .home-cms-content .home-cms-title .home-cms-title-text {
  color: #2B3139;
  padding-left: 1.5rem;
}

#home-cms-5 .home-cms-content .home-cms-listing {
  margin-bottom: 4rem;
}

#home-cms-5 .home-cms-content .home-cms-listing > div {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div {
    display: block;
  }
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item {
  flex: 1;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item:first-child {
  margin-right: 1.5rem;
}

@media (max-width: 600px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item:first-child {
    margin: 0;
    margin-bottom: 2rem;
  }
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item:last-child {
  margin-left: 1.5rem;
}

@media (max-width: 600px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item:last-child {
    margin: 0;
    margin-bottom: 2rem;
  }
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text {
  border: 0.1rem solid #696969;
  background-color: #696969;
  margin-bottom: 4rem;
  position: relative;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 29%;
  border-width: 20px 15px 0;
  border-style: solid;
  border-color: #696969 transparent;
  display: block;
  width: 0;
}

@media (max-width: 1024px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:before {
    left: 25%;
  }
}

@media (max-width: 991px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:before {
    left: 15%;
  }
}

@media (max-width: 600px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:before {
    left: 29%;
  }
}

@media (max-width: 480px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:before {
    left: 18%;
  }
}

@media (max-width: 325px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:before {
    left: 10%;
  }
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 29%;
  border-width: 20px 15px 0;
  border-style: solid;
  border-color: #696969 transparent;
  display: block;
  width: 0;
}

@media (max-width: 1024px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:after {
    left: 25%;
  }
}

@media (max-width: 991px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:after {
    left: 15%;
  }
}

@media (max-width: 600px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:after {
    left: 29%;
  }
}

@media (max-width: 480px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:after {
    left: 18%;
  }
}

@media (max-width: 325px) {
  #home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text:after {
    left: 10%;
  }
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text > div {
  color: #FFFFFF;
  padding: 3rem 3rem 3rem 7rem;
  position: relative;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text > div:before {
  content: '\f10d';
  font-family: 'fontAwesome';
  color: #DC2826;
  position: absolute;
  top: 3rem;
  left: 3rem;
  font-size: 20px;
  font-size: 2rem;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-text > div:after {
  content: '\f10e';
  font-family: 'fontAwesome';
  color: #DC2826;
  padding-left: 3rem;
  font-size: 12px;
  font-size: 1.2rem;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-person > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-person > div .person-details {
  padding-left: 2rem;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-person > div .person-details > div .person-name {
  color: #2B3139;
}

#home-cms-5 .home-cms-content .home-cms-listing > div .home-cms-item .home-cms-testimonial-person > div .person-details > div .person-company {
  color: #A2A2A2;
}

#home-cms-5 .home-cms-content .home-cms-link {
  text-align: center;
  margin-bottom: 3rem;
}

#home-cms-5 .home-cms-content .home-cms-link a {
  display: inline-block;
  color: #DC2826;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  border: 1px solid #DC2826;
  background: transparent;
  border-radius: 25px;
  transition: all 0.3s linear;
}

#home-cms-5 .home-cms-content .home-cms-link a:hover {
  background: #DC2826;
  color: #FFFFFF;
}

#home-cms-5 .home-cms-content .home-cms-link a svg {
  margin-left: 0.5rem;
}

#home-cms-6 {
  background: #696969;
}

#home-cms-6 .home-cms-content {
  display: flex;
  align-items: stretch;
}

@media (max-width: 1024px) {
  #home-cms-6 .home-cms-content {
    display: block;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-left {
  flex: 4;
}

#home-cms-6 .home-cms-content .home-cms-content-left > div {
  padding-top: 3rem;
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-title {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

@media (max-width: 1024px) {
  #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-title {
    justify-content: center;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-title .home-cms-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 0.4rem solid #DC2826;
  margin-left: 1.5rem;
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-title .home-cms-title-wrapper .home-cms-title-text {
  color: #FFFFFF;
  padding-left: 1.5rem;
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

@media (max-width: 1024px) {
  #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .title {
  color: #FFFFFF;
}

@media (max-width: 480px) {
  #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .title {
    margin-bottom: 0.5rem;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .text {
  color: #FFFFFF;
}

@media (max-width: 480px) {
  #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .text {
    margin-bottom: 0.5rem;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .text svg {
  display: none !important;
}

@media (max-width: 480px) {
  #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-description .home-cms-description-item .text svg {
    margin-right: 0.5rem;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-newsletter {
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) {
  #home-cms-6 .home-cms-content .home-cms-content-left .home-cms-newsletter {
    justify-content: center;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-newsletter svg {
  color: #FFFFFF;
  cursor: pointer;
  transition: all 0.3s linear;
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-newsletter .text {
  margin-left: 1rem;
  color: #FFFFFF;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s linear;
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-newsletter:hover svg {
  color: #FFFFFF;
}

#home-cms-6 .home-cms-content .home-cms-content-left .home-cms-newsletter:hover .text {
  color: #FFFFFF;
}

#home-cms-6 .home-cms-content .home-cms-content-right {
  flex: 5;
  border-left: 0.1rem solid #FFFFFF;
}

@media (max-width: 1024px) {
  #home-cms-6 .home-cms-content .home-cms-content-right {
    border: none;
    padding: 1.5rem 0 2rem;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-right > div {
  display: flex;
  align-items: center;
  padding-top: 3rem;
  padding-left: 3rem;
  padding-bottom: 3rem;
}

@media (max-width: 1024px) {
  #home-cms-6 .home-cms-content .home-cms-content-right > div {
    padding: 2rem 0 0;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-left {
  flex: 1;
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right {
  flex: 2;
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div {
  padding-left: 3rem;
}

@media (max-width: 480px) {
  #home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div {
    padding-left: 2rem;
  }
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 0.4rem solid #DC2826;
  margin-bottom: 2rem;
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-title-wrapper .home-cms-title-text {
  color: #FFFFFF;
  padding-left: 1.5rem;
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-description .home-cms-item {
  margin-bottom: 1.5rem;
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-description .home-cms-item .title {
  color: #FFFFFF;
  margin-bottom: 1rem;
}

#home-cms-6 .home-cms-content .home-cms-content-right > div .home-cms-inner-right > div .home-cms-description .home-cms-item .description {
  color: #FFFFFF;
}

#home-cms-7 {
  background: #E7EAEE;
  padding: 6rem 0;
  margin-bottom: 5rem;
}

#home-cms-7 .home-cms-title {
  color: #2B3139;
  margin-bottom: 4rem;
  padding-left: 8rem;
  position: relative;
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-title {
    padding-left: 0;
  }
}

#home-cms-7 .home-cms-title:before {
  content: '';
  background: #DC2826;
  width: 5rem;
  height: 0.4rem;
  position: absolute;
  left: 0;
  top: 50%;
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-title:before {
    display: none;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-top {
  border-bottom: 0.1rem solid #D7DADD;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-top {
    border-bottom: none;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-top > div {
  display: flex;
  align-items: stretch;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div {
    flex-wrap: wrap;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left {
  flex: 1;
  border-right: 0.1rem solid #D7DADD;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left {
    flex: unset;
    width: 50%;
    order: 1;
    border: none;
  }
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left {
    width: 100%;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div {
  padding: 0 2.5rem;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-title {
  color: #2B3139;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content > div {
  display: flex;
  align-items: center;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content > div .home-cms-content-number {
  opacity: 0;
  background-image: linear-gradient(to right, #9B1122, #DC2826);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content > div .home-cms-content-text {
  padding-left: 1.5rem;
  color: #686C72;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content .home-cms-content-items {
  display: block;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content .home-cms-content-items .home-cms-content-item {
  margin-bottom: 1.5rem;
  padding-left: 2.5rem;
  position: relative;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content .home-cms-content-items .home-cms-content-item:before {
  content: '';
  background: #DC2826;
  height: 0.2rem;
  width: 1.5rem;
  position: absolute;
  left: 0;
  top: 0.8rem;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-title {
  color: #686C72;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle {
  color: #686C72;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle {
  flex: 2;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle {
    flex: unset;
    width: 100%;
    order: 3;
    margin-top: 2.5rem;
  }
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle {
    order: 2;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div {
  padding: 0 2.5rem;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-title {
  color: #2B3139;
  margin-bottom: 2rem;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-listing {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-listing .home-cms-content-listing-item {
  width: 25%;
  margin-bottom: 2.5rem;
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-listing .home-cms-content-listing-item {
    width: 50%;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-listing .home-cms-content-listing-item .image {
  text-align: center;
  margin-bottom: 0.5rem;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-middle > div .home-cms-content-listing .home-cms-content-listing-item .text {
  color: #686C72;
  text-align: center;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right {
  flex: 1;
  border-left: 0.1rem solid #D7DADD;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right {
    flex: unset;
    width: 50%;
    order: 2;
  }
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right {
    width: 100%;
    order: 3;
    border: none;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div {
  padding: 0 2.5rem;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div .home-cms-content-title {
  color: #2B3139;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div .home-cms-content-content > div .home-cms-content-percentage {
  background-image: linear-gradient(to right, #9B1122, #DC2826);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  display: inline-block;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle {
  color: #686C72;
  padding-left: 2.5rem;
  position: relative;
}

#home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-right > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle:before {
  content: '';
  background: #DC2826;
  height: 0.2rem;
  width: 1.5rem;
  position: absolute;
  left: 0;
  top: 0.8rem;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div {
  display: flex;
  align-items: stretch;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-bottom > div {
    flex-wrap: wrap;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left {
  flex: 2;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left {
    width: 100%;
    flex: unset;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div {
  padding: 2.5rem;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-title {
  color: #2B3139;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items {
  display: flex;
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items {
    flex-wrap: wrap;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item {
  flex: 1;
  padding-right: 15px;
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item {
    flex: unset;
    width: 100%;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-numbering {
  background-image: linear-gradient(to right, #9B1122, #DC2826);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  display: inline-block;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-numbering .small {
  vertical-align: super;
  margin-left: -1.5rem;
  font-size: 40px;
  font-size: 4rem;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle {
  color: #686C72;
  padding-left: 2.5rem;
  position: relative;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle:before {
  content: '';
  background: #DC2826;
  height: 0.2rem;
  width: 1.5rem;
  position: absolute;
  left: 0;
  top: 0.8rem;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-item-description {
  margin-top: 2rem;
  color: #686C72;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-left > div .home-cms-content-items .home-cms-content-item .home-cms-content-item-description .bolder {
  background-image: linear-gradient(to right, #9B1122, #DC2826);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right {
  flex: 1;
  border-left: 0.1rem solid #D7DADD;
}

@media (max-width: 991px) {
  #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right {
    border: none;
    width: 100%;
    flex: unset;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div {
  padding: 2.5rem;
}

@media (max-width: 480px) {
  #home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div {
    padding: 0 2.5rem;
  }
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div .home-cms-content-title {
  color: #2B3139;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div .home-cms-content-content > div .home-cms-content-percentage {
  background-image: linear-gradient(to right, #9B1122, #DC2826);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  display: inline-block;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle {
  color: #686C72;
  padding-left: 2.5rem;
  position: relative;
}

#home-cms-7 .home-cms-content .home-cms-content-bottom > div .home-cms-content-right > div .home-cms-content-content .home-cms-content-items .home-cms-content-item .home-cms-content-item-subtitle:before {
  content: '';
  background: #DC2826;
  height: 0.2rem;
  width: 1.5rem;
  position: absolute;
  left: 0;
  top: 0.8rem;
}

footer {
  background: #696969;
}

footer #footer-content {
  padding: 1rem 0;
  border-top: 0.1rem solid #FFFFFF;
  position: relative;
}

footer #footer-content .footer-bottom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 480px) {
  footer #footer-content .footer-bottom-content {
    text-align: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

footer #footer-content .footer-bottom-content .footer-bottom-left .text {
  color: #FFFFFF;
}

footer #footer-content .footer-bottom-content .footer-bottom-left .text a {
  color: #FFFFFF;
  transition: all 0.3s linear;
}

footer #footer-content .footer-bottom-content .footer-bottom-left .text a:hover {
  color: #FFFFFF;
}

.back-to-top {
  background: #9B1122;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  border-radius: 100px;
  width: 6rem;
  height: 6rem;
  position: absolute;
  bottom: 6rem;
  right: 2rem;
}

.back-to-top:hover {
  background: #DC2826;
}

.back-to-top svg {
  color: #FFFFFF;
}

.back-to-top > div {
  color: #FFFFFF;
  margin-top: -0.4rem;
}

@media (max-width: 480px) {
  #social-icon {
    margin-bottom: 1rem;
  }
}

#social-icon ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#social-icon ul li {
  display: inline-block;
  margin-left: 1rem;
  vertical-align: middle;
}

@media (max-width: 480px) {
  #social-icon ul li {
    margin: 0 1rem;
  }
}

#social-icon ul li a {
  font-size: 14px;
  font-size: 1.4rem;
  color: #FFFFFF;
  transition: all 0.3s linear;
}

#social-icon ul li a:hover {
  color: #FFFFFF;
}

#inner-banner-wrapper {
  position: relative;
}

#inner-banner-wrapper #inner-banner {
  margin: 0;
}

#inner-banner-wrapper #inner-banner .slide {
  width: 100%;
  height: 290px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 991px) {
  #inner-banner-wrapper #inner-banner .slide {
    height: 170px;
  }
}

#inner-banner-wrapper .inner-banner-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 40rem;
  text-align: center;
}

#inner-banner-wrapper .inner-banner-title .title {
  color: #FFFFFF;
}

.inner-title {
  color: #2B3139;
  max-width: 65%;
  margin: 0 0 4rem;
}

@media (max-width: 991px) {
  .inner-title {
    max-width: 100%;
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.inner-title-2 {
  color: #2B3139;
  text-align: center;
}

@media (max-width: 991px) {
  .inner-title-2 {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.inner-divider {
  background: #DC2826;
  width: 4rem;
  height: 0.2rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.inner-cms-content {
  margin-bottom: 4rem;
}

.inner-cms-content .inner-cms-split {
  display: flex;
  align-items: center;
}

.inner-cms-content .inner-cms-title {
  color: #2B3139;
  margin-bottom: 3rem;
}

.inner-cms-content .inner-cms-description {
  color: #2B3139;
}

.inner-cms-content .inner-cms-description .bold {
  color: #9B1122;
}

.inner-cms-content .inner-cms-content-left {
  flex: 1;
}

.inner-cms-content .inner-cms-content-left > div {
  padding-right: 3rem;
}

.inner-cms-content .inner-cms-content-left > div .inner-cms-content-logo {
  margin-bottom: 4rem;
}

.inner-cms-content .inner-cms-content-left > div .inner-cms-content-title {
  color: #9B1122;
  margin-bottom: 1.5rem;
}

.inner-cms-content .inner-cms-content-left > div .inner-cms-content-description {
  color: #2B3139;
}

.inner-cms-content .inner-cms-content-left > div .inner-cms-content-description .bold {
  color: #9B1122;
}

.inner-cms-content .inner-cms-content-right {
  flex: 1;
}

@media (max-width: 600px) {
  .inner-cms-content .inner-cms-content-right {
    display: none;
  }
}

.inner-cms-content .inner-cms-listing {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .inner-cms-content .inner-cms-listing {
    flex-wrap: wrap;
  }
}

.inner-cms-content .inner-cms-listing .inner-cms-item {
  flex: 1;
}

.inner-cms-content .inner-cms-listing .inner-cms-item > div {
  margin: 1rem;
}

.inner-cms-content .inner-cms-listing .inner-cms-item > div .inner-cms-item-title {
  color: #9B1122;
}

.inner-cms-content .inner-cms-listing .inner-cms-item > div .inner-cms-item-subtitle {
  color: #9B1122;
}

.inner-cms-content .inner-cms-listing .inner-cms-item > div .inner-cms-item-divider {
  background: #DC2826;
  width: 4rem;
  height: 0.2rem;
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.inner-cms-content .inner-cms-listing ul {
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  list-style: none;
  display: flex;
  align-items: stretch;
}

@media (max-width: 600px) {
  .inner-cms-content .inner-cms-listing ul {
    flex-wrap: wrap;
  }
}

.inner-cms-content .inner-cms-listing ul .inner-cms-item > div {
  display: flex;
  align-items: stretch;
}

.inner-cms-content .inner-cms-listing ul .inner-cms-item > div .inner-cms-item-icon {
  width: 5rem;
}

.inner-cms-content .inner-cms-listing ul .inner-cms-item > div .inner-cms-item-text {
  width: calc(100% - 5rem);
  color: #2B3139;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.search-page form .form-group .input-group .input-group-addon {
  background: #2B3139;
  border-color: #2B3139;
  border-radius: 0;
}

.search-page form .form-group .input-group .input-group-addon i, .search-page form .form-group .input-group .input-group-addon svg {
  color: #FFFFFF;
}

.search-page form .form-group .input-group .form-control {
  border-radius: 0;
}

.search-page .panel-default > .panel-heading {
  color: #FFFFFF;
  background-color: #2B3139;
  border-color: #2B3139;
  border-radius: 0;
}

.search-page h5 {
  margin: 10px 0;
}

.search-page .panel, .search-page table {
  border-radius: 0 !important;
}

.search-page td {
  padding: 5px 15px !important;
}

.search-page .panel > .table:last-child > tbody:last-child > tr:last-child, .search-page .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .search-page .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child {
  border-radius: 0 !important;
}

#frmContact .btn {
  border-radius: 5rem;
}

#frmContact .btn svg {
  margin-left: 0.5rem;
}

#news-mini .news .slide .date {
  font-size: 12px;
  font-family: "Gotham-Book", sans-serif;
  font-weight: bold;
}

blockquote {
  border-left: 2px solid #00B8F4;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 20px;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Gotham-Book", sans-serif;
  font-weight: bold;
  line-height: 1;
}

h1 {
  font-size: 42px;
  font-style: normal;
  margin: 0 0 25px;
}

h2 {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 25px;
}

h3 {
  font-size: 24px;
  font-style: normal;
  margin: 0 0 25px;
}

h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 25px;
}

h5 {
  font-size: 16px;
  font-style: normal;
  margin: 0 0 25px;
}

p {
  margin: 0 0 20px;
  font-size: 12px;
}

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 20px;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word;
}

a {
  color: #00B8F4;
  display: inline-block;
  transition: all 0.3s linear;
}

a:hover, a:focus {
  text-decoration: none;
}

/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/
html, body, #wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

footer {
  display: table-row;
  height: 1px;
}

.mm-page {
  height: 100%;
}

/* columns of same height styles */
.row-full-height {
  height: 100%;
}

.col-full-height {
  height: 100%;
  vertical-align: middle;
}

.row-same-height {
  display: table;
  table-layout: fixed;
}

/*revert back to normal in md*/
.row-md-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 992px) {
  .row-md-same-height {
    display: block;
  }
}

.row-sm-same-height {
  display: table;
  table-layout: fixed;
}

@media (max-width: 768px) {
  .row-sm-same-height {
    display: block;
  }
}

.col-xs-height {
  display: table-cell;
  float: none !important;
}

/*alignments*/
.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
  }
}

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
  }
}

img,
video,
audio,
iframe,
object {
  max-width: 100%;
}

.btn {
  display: inline-block;
  color: #DC2826;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  border: 1px solid #DC2826;
  background: transparent;
  border-radius: 0;
  transition: all 0.3s linear;
}

.btn:hover, .btn:focus {
  background: #DC2826;
  border-color: #DC2826;
  color: #FFFFFF;
}

.btn:active {
  background: #0092c1;
  border-color: #0092c1;
  color: #fff;
}

.btn-primary {
  display: inline-block;
  color: #DC2826;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  border: 1px solid #DC2826;
  background: transparent;
  border-radius: 0;
  transition: all 0.3s linear;
}

.btn-primary:hover, .btn-primary:focus {
  background: #DC2826;
  border-color: #DC2826;
  color: #FFFFFF;
}

.btn-primary:active {
  background: #0092c1;
  border-color: #0092c1;
  color: #fff;
}

.btn-lg {
  font-size: 1.4em;
  height: 66px;
  padding: 0 60px;
}

.btn-sm {
  font-size: .85em;
  height: 30.8px;
  padding: 0 10px;
}

.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: #00B8F4;
  text-shadow: none;
  text-transform: none;
}

.btn-link:hover, .btn-link:focus {
  background: transparent;
  color: #00B8F4;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .btn {
    display: block;
    margin-bottom: 10px;
    width: 100%;
  }
}

table {
  width: 100%;
}

table th,
table td {
  padding: 5px;
  text-align: left;
}

table th {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 44px;
  outline: none;
  padding: 5px 10px;
  width: 300px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=phone]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
textarea:focus {
  border-color: #00B8F4;
}

input[type=text].full-width,
input[type=email].full-width,
input[type=phone].full-width,
input[type=password].full-width,
input[type=number].full-width,
input[type=search].full-width,
textarea.full-width {
  width: 100%;
}

textarea {
  height: 132px;
}

form {
  margin-bottom: 40px;
}

form .field {
  margin-bottom: 20px;
}

form .hint {
  color: #aaa;
  margin-top: 5px;
}

form .is-required {
  font-style: italic;
}

form .has-error .error-description {
  color: #ff4136;
  margin-top: 5px;
}

form .has-error input[type=text],
form .has-error input[type=email],
form .has-error input[type=phone],
form .has-error input[type=password],
form .has-error input[type=number],
form .has-error input[type=search],
form .has-error textarea {
  border-color: #ff4136;
}

.alert {
  zoom: 1;
  border-left: 3px solid;
  margin-bottom: 40px;
  padding: 10px;
}

.alert:before, .alert:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden;
}

.alert:after {
  clear: both;
}

.alert.alert-danger {
  background: #ffeae9;
  border-color: #ff4136;
  color: #e90d00;
}

.alert.alert-success {
  background: #e6f9e8;
  border-color: #2ecc40;
  color: #25a233;
}

.alert.alert-warning {
  background: #fff2e7;
  border-color: #ff851b;
  color: #e76b00;
}

.alert.alert-info {
  background: #cfe9ff;
  border-color: #0074d9;
  color: #0059a6;
}

.alert.alert-empty {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  text-align: center;
}

.alert p:last-child {
  margin-bottom: 0;
}

* {
  box-sizing: border-box !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.valign {
  vertical-align: middle;
}

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 40px 0;
}

.subtle {
  color: #aaa;
}

.relative {
  position: relative;
}

[data-banner] {
  transition: all 0.1s linear 0s;
}

/*
    ADD YOUR OWN TRANSITION STYLE HERE IF YOU WANT TO CUSTOMISE
    - banner-transition
    - banner-timing
    - banner-duration
    - banner-delay
*/
[data-banner-transition='fade-in'] {
  opacity: 0 !important;
}

[data-banner-transition='fade-down'] {
  opacity: 0 !important;
  transform: translate(0, -30px) !important;
}

[data-banner-transition='fade-up'] {
  opacity: 0 !important;
  transform: translate(0, 30px) !important;
}

[data-banner-transition='fade-right'] {
  opacity: 0 !important;
  transform: translate(-30px, 0) !important;
}

[data-banner-transition='fade-left'] {
  opacity: 0 !important;
  transform: translate(30px, 0) !important;
}

[data-banner-transition='slide-down'] {
  opacity: 1 !important;
  transform: translate(0, -30px) !important;
}

[data-banner-transition='slide-up'] {
  opacity: 1 !important;
  transform: translate(0, 30px) !important;
}

[data-banner-transition='slide-right'] {
  opacity: 1 !important;
  transform: translate(-30px, 0) !important;
}

[data-banner-transition='slide-left'] {
  opacity: 1 !important;
  transform: translate(30px, 0) !important;
}

[data-banner-transition='rotate-left'] {
  opacity: 0 !important;
  transform: translate(0, 0) rotate(360deg) !important;
}

[data-banner-transition='rotate-right'] {
  opacity: 0 !important;
  transform: translate(0, 0) rotate(-360deg) !important;
}

[data-banner-transition='flip-left'] {
  opacity: 0 !important;
  transform: translate(0, 0) rotateY(180deg) !important;
}

[data-banner-transition='flip-right'] {
  opacity: 0 !important;
  transform: translate(0, 0) rotateY(-180deg) !important;
}

.initial .slick-active [data-banner] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53) !important;
}

.initial .slick-active [data-banner-transition] {
  transform: translate(0, 0) rotate(0) !important;
  opacity: 1 !important;
}

.initial .slick-active [data-banner-timing='linear'] {
  transition-timing-function: linear !important;
}

.initial .slick-active [data-banner-timing='ease-in'] {
  transition-timing-function: ease-in !important;
}

.initial .slick-active [data-banner-timing='ease-out'] {
  transition-timing-function: ease-out !important;
}

.initial .slick-active [data-banner-timing='ease-in-out'] {
  transition-timing-function: ease-in-out !important;
}

.initial .slick-active [data-banner-duration='100'] {
  transition-duration: 0.1s !important;
}

.initial .slick-active [data-banner-duration='200'] {
  transition-duration: 0.2s !important;
}

.initial .slick-active [data-banner-duration='300'] {
  transition-duration: 0.3s !important;
}

.initial .slick-active [data-banner-duration='400'] {
  transition-duration: 0.4s !important;
}

.initial .slick-active [data-banner-duration='500'] {
  transition-duration: 0.5s !important;
}

.initial .slick-active [data-banner-duration='600'] {
  transition-duration: 0.6s !important;
}

.initial .slick-active [data-banner-duration='700'] {
  transition-duration: 0.7s !important;
}

.initial .slick-active [data-banner-duration='800'] {
  transition-duration: 0.8s !important;
}

.initial .slick-active [data-banner-duration='900'] {
  transition-duration: 0.9s !important;
}

.initial .slick-active [data-banner-duration='1000'] {
  transition-duration: 1s !important;
}

.initial .slick-active [data-banner-duration='1500'] {
  transition-duration: 1.5s !important;
}

.initial .slick-active [data-banner-duration='2000'] {
  transition-duration: 2s !important;
}

.initial .slick-active [data-banner-duration='2500'] {
  transition-duration: 2.5s !important;
}

.initial .slick-active [data-banner-delay='100'] {
  transition-delay: 0.1s !important;
}

.initial .slick-active [data-banner-delay='200'] {
  transition-delay: 0.2s !important;
}

.initial .slick-active [data-banner-delay='300'] {
  transition-delay: 0.3s !important;
}

.initial .slick-active [data-banner-delay='400'] {
  transition-delay: 0.4s !important;
}

.initial .slick-active [data-banner-delay='500'] {
  transition-delay: 0.5s !important;
}

.initial .slick-active [data-banner-delay='600'] {
  transition-delay: 0.6s !important;
}

.initial .slick-active [data-banner-delay='700'] {
  transition-delay: 0.7s !important;
}

.initial .slick-active [data-banner-delay='800'] {
  transition-delay: 0.8s !important;
}

.initial .slick-active [data-banner-delay='900'] {
  transition-delay: 0.9s !important;
}

.initial .slick-active [data-banner-delay='1000'] {
  transition-delay: 1s !important;
}

.initial .slick-active [data-banner-delay='1500'] {
  transition-delay: 1.5s !important;
}

.initial .slick-active [data-banner-delay='2000'] {
  transition-delay: 2s !important;
}

.initial .slick-active [data-banner-delay='2500'] {
  transition-delay: 2.5s !important;
}

.no-padding {
  padding: 0;
}

.left-0-padding {
  padding-left: 0;
}

.right-0-padding {
  padding-right: 0;
}

.top-0-padding {
  padding-top: 0;
}

.bottom-0-padding {
  padding-bottom: 0;
}

.dark-blue {
  color: #004161;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.title-lg-inline {
  display: inline-block;
}

@media (max-width: 991px) {
  .title-lg-inline {
    display: block;
  }
}

.title-md-inline {
  display: inline-block;
}

@media (max-width: 768px) {
  .title-md-inline {
    display: block;
  }
}

.center-of-unknown:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.center-of-unknown > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#menuBtn {
  font-size: 14px;
  font-size: 1.4rem;
}

#menuBtn a {
  padding: 1rem 2rem;
  color: #FFFFFF;
}

#banner {
  position: relative;
  transition: all 0.5s ease-in-out;
  /*end of slides*/
}

#banner .slides {
  margin: 0;
}

#banner .slides .slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 535px;
  box-shadow: inset 0 0 1000px 1000px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1024px) {
  #banner .slides .slide {
    height: 400px;
  }
}

@media (max-width: 600px) {
  #banner .slides .slide {
    height: 250px;
  }
}

#banner .slides .slide[href="#"] {
  cursor: default;
}

#banner .descs {
  width: 100%;
  max-width: 620px;
  position: absolute;
  padding: 0 30px;
  margin-bottom: 0;
  bottom: 20%;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 600px) {
  #banner .descs {
    max-width: 100%;
    left: 0;
    right: 0;
  }
}

#banner .descs .desc .title {
  text-transform: uppercase;
  font-size: 25px;
  font-size: 2.5rem;
  color: #FFFFFF;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

@media (max-width: 600px) {
  #banner .descs .desc .title {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

#banner .descs .desc p {
  font-size: 35px;
  font-size: 3.5rem;
  color: #FFFFFF;
  margin-bottom: 0;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

@media (max-width: 600px) {
  #banner .descs .desc p {
    font-size: 20px;
    font-size: 2rem;
  }
}

#banner .descs .desc .link {
  margin-top: 2rem;
  padding-bottom: 0.5rem;
}

#banner .descs .desc .link .link-text {
  display: inline-block;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 1rem 2.5rem;
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  background-color: transparent;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

#banner .descs .desc .link .link-text:hover {
  background-color: #DC2826;
  border-color: transparent;
}

#banner .descs .desc .link .link-text svg {
  margin-left: 0.5rem;
}

#banner .banner-navigation {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

#banner .banner-navigation .banner-dots .slick-dots {
  bottom: 0;
  left: 0;
  text-align: right;
  margin-bottom: 2rem;
}

#banner .banner-navigation .banner-dots .slick-dots li {
  width: 1rem;
  margin: 0;
  margin-left: 1.5rem;
}

#banner .banner-navigation .banner-dots .slick-dots li button {
  width: 1rem;
  height: 1rem;
}

#banner .banner-navigation .banner-dots .slick-dots li button:before {
  font-size: 10px;
  font-size: 1rem;
  color: #FFFFFF;
  transition: all 0.5s ease;
  opacity: 1;
  width: auto;
  height: auto;
}

#banner .banner-navigation .banner-dots .slick-dots li.slick-active button:before, #banner .banner-navigation .banner-dots .slick-dots li button:hover:before {
  color: #DC2826;
  opacity: 1;
}

#breadcrumb {
  display: none;
  text-align: center;
  padding: 1rem 0;
}

@media (min-width: 768px) {
  #breadcrumb {
    display: block;
  }
}

#breadcrumb .text {
  color: #A2A2A2;
}

#breadcrumb .crumb {
  color: #A2A2A2;
  text-transform: uppercase;
}

#breadcrumb .crumb:after {
  content: '\f0da';
  font-family: 'fontAwesome';
  color: #A2A2A2;
  margin: 0 5px;
}

#breadcrumb .crumb:last-child:after {
  display: none;
}

#content .page-content h1, #content .page-content h2, #content .page-content h3 {
  color: #004161;
  padding: 15px 0;
  margin: 15px 0 0;
}

#content .page-content h2 {
  font-size: 24px;
}

#content .page-content p {
  color: #000000;
  font-size: 12px;
  text-align: justify;
}

#content .page-content img {
  max-width: 100%;
  display: block;
}

#content .page-content .read-more {
  color: #004161;
  font-size: 13px;
  position: relative;
}

#content .page-content .read-more:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: #1ca7e0;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid #1ca7e0;
  border-left-color: inherit;
}

#content .page-content .left-content {
  background-color: #ffffff;
}

#content .page-content .left-content > *:last-child {
  margin-bottom: 30px;
}

#content .page-content .right-content {
  background-color: #F3F3F3;
}

#content .page-content .right-content > *:last-child {
  margin-bottom: 30px;
}

#content .bottom-content {
  margin-top: 30px;
}

#news-mini {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
  /*end of news*/
}

#news-mini:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .title-cont {
  position: relative;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  #news-mini .title-cont {
    width: 100%;
    border: none;
    box-shadow: none;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0083B4;
    box-shadow: #16BCF9 0 1px 0 0;
    transition: all 0.5s ease-in-out;
  }
}

#news-mini .title-cont .title {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  #news-mini .title-cont .title {
    display: inline-block;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  #news-mini .title-cont .title {
    display: inline-block;
  }
}

#news-mini .title-cont .link {
  color: #004161;
  display: inline-block;
  font-size: 13px;
  position: relative;
}

#news-mini .title-cont .link:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-color: white;
  top: 50%;
  left: 100%;
  margin-left: 5px;
  margin-top: -3px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid white;
  border-left-color: inherit;
}

@media (max-width: 768px) {
  #news-mini .title-cont .link {
    float: right;
  }
}

#news-mini .news {
  transition: opacity 0.5s ease-in-out;
  border-right: 1px solid #0083B4;
  box-shadow: #16BCF9 1px 0 0 0;
}

@media (max-width: 991px) {
  #news-mini .news {
    border: none;
    box-shadow: none;
  }
}

#news-mini .news .slide:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .news .slide > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .news .slide .date {
  color: #004161;
  text-align: center;
}

#news-mini .news .slide .date .day {
  font-size: 30px;
}

#news-mini .news .slide .date .month {
  font-size: 14px;
}

#news-mini .news .slide .desc .title {
  color: #004161;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: inline-block;
  max-width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

#news-mini .news .slide .desc p {
  color: white;
  font-size: 13px;
  margin: 0;
}

#news-mini .news .slide .desc a {
  display: block;
}

#news-mini .news .slide:focus {
  outline: none;
}

#news-mini .slide-arrow:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

#news-mini .slide-arrow > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%;
}

#news-mini .slide-arrow .slide-left, #news-mini .slide-arrow .slide-right {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-left:hover, #news-mini .slide-arrow .slide-right:hover {
  color: white;
}

#news-mini .slide-arrow .slide-right {
  margin-left: 5px;
}

.a-banner {
  margin-top: 30px;
}

#get-quote {
  margin-top: 30px;
}

#event-calendar {
  margin-top: 30px;
}

#home-video-cont {
  padding: 0;
  height: 279px;
}

#home-video-cont a {
  display: block;
  width: 100%;
}

#home-video-cont .vid {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#home-customer-value {
  border-top: 15px solid rgba(255, 255, 255, 0.1);
  border-bottom: 15px solid rgba(0, 0, 0, 0.06);
  padding: 20px 0;
  background-color: #1ca7e0;
  transition: all 0.5s ease-in-out;
}

#home-customer-value .title {
  color: #004161;
}

#home-customer-value img {
  margin-bottom: 15px;
}

#home-customer-value p {
  color: white;
}

#home-any-last-words {
  margin: 30px 0;
  color: #1ca7e0;
}

#popUpsContainer {
  display: none;
}

.white-popup.popup {
  padding: 10px;
}

/*inner page*/
#inner {
  background-color: white;
}

#inner.no-banner {
  margin-top: 0;
}

#inner .inner {
  margin-top: 2rem;
}

#inner #newsletter {
  margin: 0 -15px 30px;
  display: block;
  min-height: 100px;
  width: auto;
}

.editor-mode [data-block-editable] {
  outline: dashed #7f7f7f 1px;
}

.editor-mode #home-cms-1 .home-cms-content .home-cms-content-left > div .home-cms-content-inside .home-cms-content-inside-content > div .home-cms-content-number {
  opacity: 1;
}

.editor-mode #home-cms-2 .home-cms-content .home-cms-listing {
  opacity: 1;
}

.editor-mode #home-cms-2 .home-cms-content .home-cms-listing .home-cms-item {
  margin-bottom: 20px;
  outline: dashed #7f7f7f 1px;
}

.editor-mode #home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing {
  opacity: 1;
}

.editor-mode #home-cms-3 .home-cms-content .home-cms-content-right > div .home-cms-listing .home-cms-items > div .home-cms-item {
  outline: dashed #7f7f7f 1px;
}

.editor-mode #home-cms-7 .home-cms-content .home-cms-content-top > div .home-cms-content-left > div .home-cms-content-content > div .home-cms-content-number {
  opacity: 1;
}

@media (min-width: 768px) {
  #divChangeViews {
    display: none;
  }
}

[data-mh] {
  transition: height 0.3s;
}

/*# sourceMappingURL=app.css.map */
